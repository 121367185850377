import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { Formik } from "formik"

import { LCard, Col2 } from "../style/containers"
import Layout from "../components/layout"
import SButton from "../components/button"
import Signature from "../components/Signature"
import Form from "../components/form/FormHelper"
import EmploymentHistory from "../components/EmploymentHistory"
import { Education } from "../components/IndustrialApplication"
import { FormikCheckbox } from "../components/form/checkbox"
import { FormikTabs } from "../components/form/tabs"
import { FormikText } from "../components/form/textarea"
import { FormikSelect } from "../components/form/select"
import { FormikInput, FormikDatepicker, Error } from "../components/form"
import { submitForm, handleSubmitResponse, FormatFormData, dateCheck, dateOfBirthCheck } from "../services"
import {
  validationSchema,
  initialValues,
} from "../components/HealthcareApplication/"
import { HealthcareUpload } from "../components/fileUpload"
import References from "../components/References"
import { TagSelector } from "../components/form/tags"
import { countryList } from "../constants"
import CovidUpload from "../components/fileUpload/covid_proof"

const IndustrialApplication = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      const formatData = {
        ...values,
        date_of_birth:
          dateCheck(values.date_of_birth),
        education: {
          ...values.education,
          start_date: dateCheck(values.education?.start_date),
          end_date: dateCheck(values.education?.end_date),
        },
        employment_history: values.employment_history.map(emp => {
          return {
            ...emp,
            date_from: dateCheck(emp.date_from),
            date_to: dateCheck(emp.date_to)
          }
        }),
        mrsa_date: dateCheck(values.mrsa_date),
        cdiff_date: dateCheck(values.cdiff_date),
        pox_shingles_date: dateCheck(values.pox_shingles_date),
        bbv_date: dateCheck(values.bbv_date),
        tuberculosis_date: dateCheck(values.tuberculosis_date),
        triple_vaccination_date: dateCheck(values.triple_vaccination_date),
        polio_date: dateCheck(values.polio_date),
        tetanus_date: dateCheck(values.tetanus_date),
        varicella_date: dateCheck(values.varicella_date),
        mmr_date: dateCheck(values.mmr_date),
        covid_first_dose_date: dateCheck(values.covid_first_dose_date),
        covid_second_dose_date: dateCheck(values.covid_second_dose_date),
      }
      const formData = await FormatFormData(formatData, sigCanvas)
      let res = await submitForm(formData, "/healthcare-application")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  return (
    <Layout healthcare emailNoCheck={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Healthcare Company Application Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            setFieldValue,
            values,
            isSubmitting,
            errors,
            validateForm,
            setFieldTouched,
          }) => (
            <Form>
              <h1>Healthcare Company Form</h1>
              <Col2>
                <FormikInput
                  name="consultant_name"
                  type="text"
                  label="Consultant name"
                />
              </Col2>
              <h2>Personal details</h2>
              <Col2>
                <FormikSelect
                  label="Title"
                  name="title"
                  options={[
                    { value: "Mr", label: "Mr" },
                    { value: "Mrs", label: "Mrs" },
                    { value: "Miss", label: "Miss" },
                    { value: "Ms", label: "Ms" },
                    { value: "Other", label: "Other" },
                  ]}
                  colorTheme="healthcare"
                />
              </Col2>
              <Col2>
                <FormikInput name="first_name" type="text" label="First name" />
                <FormikInput name="surname" type="text" label="Surname" />
                <FormikInput
                  type="text"
                  label="Address line 1 - house number and street name"
                  name="address_1"
                />
                <FormikInput
                  type="text"
                  label="Address line 2"
                  name="address_2"
                />
                <FormikInput
                  type="text"
                  label="Town/City"
                  name="address_town"
                />
                <FormikInput type="text" label="County" name="address_3" />
                <FormikInput name="postcode" type="text" label="Postcode" />
                <FormikInput type="text" label="Country" name="country" />
                <FormikDatepicker
                  name="date_of_birth"
                  placeholderText="Select a date"
                  label="Date of Birth"
                  maxDate={dateOfBirthCheck()}
                />
                <FormikInput name="mobile" type="tel" label="Mobile" />
                <FormikInput
                  label="Landline"
                  name="landline"
                  type="tel"
                  optional={true}
                />
                <FormikInput name="email" type="email" label="Email" />
                <FormikInput
                  name="national_insurance"
                  type="text"
                  label="National Insurance No:"
                />
              </Col2>
              <h2>PRIVACY NOTICE (GDPR)</h2>
              <p>
                We at Pin Point Healthcare are committed to protecting the
                privacy of our candidates, clients and users of our website.
                When you use Pin Point Healthcare you trust us with your
                information and we are committed to preserving that trust and
                providing a safe and secure user experience. We will ensure that
                the information you submit to us via our website or through any
                of our offices is only used for the purposes set out in this
                Data Privacy Notice.
              </p>
              <p>
                The General Data Protection Regulation (GDPR) (Regulation (EU)
                2016/679) replaces the Data Protection Regulation (Directive
                95/46/EC) from 25 May 2018. The Regulation aims to harmonise
                data protection legislation across EU member states, enhancing
                privacy rights for individuals and providing a strict framework
                within which commercial organizations can legally operate. Even
                though the UK has now entered a transition period to leave the
                EU from January 2020, the GDPR will be applicable in the UK from
                25th May 2018.
              </p>
              <p>
                As of May 25th 2018, Pin Point Healthcare are compliant with the
                General Data Protection Regulation (GDPR). You can find our
                Privacy Notice on our website at{" "}
                <a href="http://www.pinpoint-health.co.uk/gdpr" target="_blank">
                  http://www.pinpoint-health.co.uk/gdpr
                </a>{" "}
                This can also be emailed out to you if requested, or available
                to read in paper format at your local Pin Point office. Once you
                have been inducted, and become an employee/a consultancy of Pin
                Point, you will receive your Staff Handbook, in which the GDPR
                Privacy Notice can also be found.
              </p>
              <p>
                GDPR Notice – Can also be referred to in your Staff Handbook.
              </p>
              <FormikCheckbox
                name="GDPR"
                label="I confirm that I agree to the GDPR notice above."
                colorTheme="healthcare"
                bold={true}
                style={{ marginTop: "2rem" }}
              />
              <HealthcareUpload colorTheme="healthcare" />
              <hr />
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="has_full_driving_licence"
                  label="Do you have a full UK driving licence?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                <TagSelector
                  label="What are your current methods of transport?"
                  name="method_of_transport"
                  options={["Bike", "Bus", "Car", "Walk", "Other"]}
                />
              </Col2>
              <Col2>
                <h4>Nationality</h4>
                <FormikSelect
                  label="Nationality"
                  name="nationality"
                  options={countryList}
                />
              </Col2>
              <Col2>
                <FormikInput
                  name="date_arrived"
                  type="text"
                  label="UK Arrival Date"
                  disabled={values?.nationality === "United Kingdom" || values?.nationality === ""}
                />
                <FormikInput
                  name="share_code"
                  type="text"
                  label="Share Code"
                  disabled={values?.nationality === "United Kingdom" || values?.nationality === ""}
                />
                <FormikInput
                  name="passport_number"
                  type="text"
                  label="Passport Number"
                />
                <FormikInput
                  name="passport_issue_date"
                  type="text"
                  label="Passport Issued"
                />
                <FormikInput
                  name="passport_expiry_date"
                  type="text"
                  label="Passport Expiry"
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="eligible_uk"
                  label="Are you eligible to work in the UK?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="work_permit_uk"
                  label="Do you require a work permit to work in the UK?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                <FormikSelect
                  label="Where did you hear about the Pinpoint vacancy?"
                  name="hear_about_us"
                  options={[
                    { value: "Facebook", label: "Facebook" },
                    { value: "Indeed", label: "Indeed" },
                    { value: "CV Library", label: "CV Library" },
                    { value: "Friend", label: "Friend" },
                    { value: "Other", label: "Other" },
                  ]}
                  colorTheme="healthcare"
                />
                {values?.hear_about_us === "Friend" || values?.hear_about_us === "Other" ? (
                  <FormikInput type="text" name="hear_about_us_other" label="Please specify" />
                )
                  : null}
              </Col2>

              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="languages"
                  label="Do you speak any language other than English:"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.languages === "Yes" ? (
                  <FormikInput
                    label="If yes which language(s)"
                    name="other_languages"
                    type="text"
                    optional={true}
                  />
                ) : (
                  ""
                )}
              </Col2>
              <h4>CAUTIONS, REHABILITATION AND CRIMINAL RECORDS</h4>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="convictions"
                label="Do you have any convictions, cautions, reprimands, final warnings or any pending charges that are not “protected” as defined by the Rehabilitation of Offenders Act 1974 (Exceptions) Order 1975 (as amended in 2013)?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.convictions === "Yes" ? (
                <FormikText
                  label="If you have answered “yes” please state when, the court, the offence and disposal."
                  name="convictions_details"
                />
              ) : (
                ""
              )}
              <FormikTabs
                setFieldValue={setFieldValue}
                name="barring_decision"
                label="Are you currently bound by any barring decision made by the Independent Safeguarding Authority (ISA) or Disclosure Scotland from working with children or vulnerable adults?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              <hr />
              <p>
                Please complete if you have subscribed to the DBS Update Service
                (England and Wales) or PVG Scheme Membership (Scotland)
              </p>
              <Col2>
                <FormikInput
                  name="certificate_number"
                  type="text"
                  label="Certificate / Membership Number:"
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="pvg_membership"
                  label="(PVG scheme only) Does your membership cover"
                  tabs={[
                    { name: "Adults", label: "Adults" },
                    { name: "Children", label: "Children" },
                    { name: "Both", label: "Both" },
                  ]}
                  colorTheme="healthcare"
                />
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="pinpoint_consent"
                  label="(England and Wales) Do you consent to Pinpoint Health &amp; Social Care accessing your DBS record?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
              </Col2>
              <h2>DRIVING RECORD</h2>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="driving_licence"
                label="Do you have a current Full Driving Licence?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.driving_licence === "Yes" ? (
                <Col2>
                  <FormikTabs
                    setFieldValue={setFieldValue}
                    name="car_owner"
                    label="Are you a car owner?"
                    tabs={[
                      { name: "Yes", label: "Yes" },
                      { name: "No", label: "No" },
                    ]}
                    colorTheme="healthcare"
                  />
                  <FormikTabs
                    setFieldValue={setFieldValue}
                    name="use_own_car"
                    label="Are you willing and able to use your own car for work purposes?"
                    tabs={[
                      { name: "Yes", label: "Yes" },
                      { name: "No", label: "No" },
                    ]}
                    colorTheme="healthcare"
                  />
                  <FormikTabs
                    setFieldValue={setFieldValue}
                    name="car_insurance"
                    label="Do you have valid car insurance which includes business use?"
                    tabs={[
                      { name: "Yes", label: "Yes" },
                      { name: "No", label: "No" },
                    ]}
                    colorTheme="healthcare"
                  />
                  <FormikTabs
                    setFieldValue={setFieldValue}
                    name="mot"
                    label="Do you have a valid MOT?"
                    tabs={[
                      { name: "Yes", label: "Yes" },
                      { name: "No", label: "No" },
                    ]}
                    colorTheme="healthcare"
                  />
                </Col2>
              ) : (
                ""
              )}
              <p>
                Pinpoint Health &amp; Social Care requires you to ensure that
                your vehicle is insured and road worthy whilst working for
                Pinpoint Health &amp; Social Care and that you will only use
                vehicles that you have a valid driving licence for. Homecare
                workers will require insurance for business use.
              </p>
              <h2>Education</h2>
              <Education
                values={values}
                setFieldValue={setFieldValue}
                colorTheme="healthcare"
              />
              <h2>Employment History</h2>
              <EmploymentHistory
                values={values}
                validateForm={validateForm}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
                colorTheme="healthcare"
              />
              <h2>References</h2>
              <References
                values={values}
                validateForm={validateForm}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
                colorTheme="healthcare"
              />
              <br />
              <FormikCheckbox
                name="references_consent"
                label="I consent to Pin Point Health &amp; Social Care contacting my named referees in accordance with their
                policy if I am a successful candidate. I understand that my referees will be contacted after the interview has taken place and I am
                providing digital consent for Pin Point Health &amp; Social Care to do so."
                colorTheme="healthcare"
              />
              <h2>DECLARATION</h2>
              <p>(Please read carefully before signing this application)</p>
              <ol>
                <li>
                  I confirm that all the information is complete and correct and
                  that any untrue or misleading information will give my
                  employer the right to terminate any employment contract
                  offered.
                </li>
                <li>
                  Should we require further information and wish to contact your
                  doctor with a view to obtaining a medical report, the law
                  requires us to inform you of our intention and obtain your
                  permission prior to contacting your doctor. I agree that the
                  organisation reserves the right to require me to undergo a
                  medical examination. In addition, I agree that this
                  information will be retained in my personnel file during
                  employment and for up to six years thereafter and understand
                  that information will be processed in accordance with the
                  General Data Protection Regulation (GDPR) 2018
                </li>
                <li>
                  I understand that the personal data on this application form
                  is collected by Pinpoint Health &amp; Social Care for the
                  purpose of recruitment processes, and I consent for this data
                  to be processed and stored by Pinpoint Health &amp; Social
                  Care.
                </li>
                <li>
                  I consent to Pin Point Health &amp; Social Care contacting my
                  named referees in accordance with their policy if I am a
                  successful candidate. I understand that my referees will be
                  contacted after interview has taken place and I am providing
                  written consent for Pin Point Health &amp; Social Care to do
                  so.
                </li>
              </ol>
              <h2>
                PINPOINT HEALTH &amp; SOCIAL CARE IS AN EQUAL OPPORTUNITIES
                EMPLOYER
              </h2>
              <p>
                The sole criterion for selection of applicants will be
                suitability for the job position, regardless of age, gender,
                background, culture, ethnic background, religious affiliation,
                marital status or disability.
              </p>
              <p>
                <strong>
                  PLEASE COMPLETE THE ENCLOSED EQUAL OPPORTUNITIES FORM AND
                  BRING IT ALONG TO YOUR INTERVIEW.
                </strong>
              </p>
              <h2>EQUAL OPPORTUNITIES</h2>
              <p>
                The success of an organisation depends on people. Making the
                most of what is unique about individuals and drawing on their
                different perspectives and experiences will add value to the way
                we work.
              </p>
              <p>
                Pinpoint Health and Social Care, as an Equal Opportunities
                Employer, are committed to a policy of equality of opportunity
                in all employment practices. The sole criterion for selection of
                applicants will be suitability for the job position, regardless
                of age, gender, background, culture, ethnic background,
                religious affiliation, marital status or disability. To assist
                in the effective implementation of this policy, applicants for
                positions with Pinpoint are asked to provide the information
                below.
              </p>
              <p>
                This document will be kept separate from the Application Form.
                The information you provide will be treated in strictest
                confidence and will not be used for selection purposes. The
                information will be held purely for monitoring purposes.
              </p>
              <h3>Age</h3>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="age"
                label="(Please click a appropriate group)"
                tabs={[
                  { name: "18-24", label: "18-24yrs" },
                  { name: "25-34", label: "25-34yrs" },
                  { name: "35-44", label: "35-44yrs" },
                  { name: "45-54", label: "45-54yrs" },
                  { name: "55-64", label: "55-64yrs" },
                  { name: "65+", label: "65+yrs" },
                ]}
                colorTheme="healthcare"
              />
              <h3>Sex</h3>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="sex"
                label=""
                tabs={[
                  { name: "Male", label: "Male" },
                  { name: "Female", label: "Female" },
                ]}
                colorTheme="healthcare"
              />
              <p>
                If you are one or more of non-binary, transgender, have
                variations of sex characteristics, sometimes also known as
                intersex, the answer you give can be different from what is on
                your birth certificate.
                <br />
                If you’re not sure how to answer, use the sex registered on your
                official documents, such as passport or driving licence, or
                whichever answer best describes your sex.
                <br />A later question gives the option to tell us if your
                gender is different from your sex registered at birth, and, if
                different, to record your gender.
              </p>
              <h3>Gender</h3>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="gender"
                label="Is your gender the same as the sex you were registered at birth?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                  { name: "Prefer not", label: "Prefer not to say" },
                ]}
                colorTheme="healthcare"
              />
              <p>
                If your gender is not the same as the sex recorded on your birth
                certificate when you were born (for example, you are transgender
                or non-binary), tick “No”.
              </p>
              {values.gender === "No" ? (
                <>
                  <FormikInput
                    name="gender_term"
                    type="text"
                    label="If you answered no, please state the term you use to describe your gender:"
                  />

                  <p>
                    If you do not wish to state the term you use to describe
                    your gender then please write 'prefer not to say'.
                  </p>
                  <p>
                    If you would like to record that you have variations of sex
                    characteristics, sometimes also known as intersex, you can
                    use this write-in box. If you would like to, you can also
                    write-in your gender (for example: ‘intersex, non-binary’).
                  </p>
                </>
              ) : (
                ""
              )}
              <h3>Sexual Orientation</h3>
              <FormikSelect
                label="Which of the following best describes your sexual orientation?"
                name="sexual_orientation"
                options={[
                  { value: "Bisexual", label: "Bisexual" },
                  { value: "Gay or Lesbian", label: "Gay or Lesbian" },
                  {
                    value: "Straight Heterosexual",
                    label: "Straight/Heterosexual",
                  },
                  { value: "Other", label: "Other" },
                  { value: "Prefer not", label: "Prefer not to disclose" },
                ]}
                colorTheme="healthcare"
              />
              <h3>Religion or Belief</h3>
              <FormikSelect
                label="Which of the following best describes your religion or belief system?"
                name="religion"
                options={[
                  { value: "None", label: "No religion" },
                  { value: "Baha’i", label: "Baha’I" },
                  {
                    value: "Buddhist",
                    label: "Buddhist",
                  },
                  { value: "Christian", label: "Christian" },
                  { value: "Hindu", label: "Hindu" },
                  { value: "Jain", label: "Jain" },
                  {
                    value: "Jewish",
                    label: "Jewish",
                  },
                  { value: "Muslim", label: "Muslim" },
                  { value: "Sikh", label: "Sikh" },
                  { value: "Other", label: "Other" },
                  { value: "Prefer not", label: "Prefer not to disclose" },
                ]}
                colorTheme="healthcare"
              />
              <h3>Cultural/Ethnic Origin</h3>
              <FormikSelect
                label="Ethnic origin"
                name="origin"
                options={[
                  {
                    label: "White",
                    options: [
                      {
                        label: "English",
                        value: "English",
                      },
                      {
                        label: "Welsh",
                        value: "Welsh",
                      },
                      {
                        label: "Scottish",
                        value: "Scottish",
                      },
                      {
                        label: "Northern Irish",
                        value: "Northern Irish",
                      },
                      {
                        label: "British",
                        value: "British",
                      },
                      {
                        label: "Irish",
                        value: "Irish",
                      },
                      {
                        label: "Gypsy or Irish Traveller British",
                        value: "Gypsy or Irish Traveller British",
                      },
                      {
                        label: "Any other White Background British",
                        value: "Any other White Background British",
                      },
                    ],
                  },
                  {
                    label: "Mixed/multiple ethnic groups",
                    options: [
                      {
                        label: "White and Black Caribbean",
                        value: "White and Black Caribbean",
                      },
                      {
                        label: "White and Black African",
                        value: "White and Black African",
                      },
                      {
                        label: "White and Asian",
                        value: "White and Asian",
                      },
                      {
                        label: "Any other Mixed/Multiple ethnic background",
                        value: "Any other Mixed/Multiple ethnic background",
                      },
                    ],
                  },
                  {
                    label: "Asian/Asian British",
                    options: [
                      {
                        label: "Indian",
                        value: "Indian",
                      },
                      {
                        label: "Pakistani",
                        value: "Pakistani",
                      },
                      {
                        label: "Bangladeshi",
                        value: "Bangladeshi",
                      },
                      {
                        label: "Chinese",
                        value: "Chinese",
                      },
                      {
                        label: "Any other Asian background",
                        value: "Any other Asian background",
                      },
                    ],
                  },
                  {
                    label: "Black/African/Caribbean/Black British",
                    options: [
                      {
                        label: "African",
                        value: "African",
                      },
                      {
                        label: "Caribbean",
                        value: "Caribbean",
                      },
                      {
                        label: "Any other Black/African/Caribbean background",
                        value: "Any other Black/African/Caribbean background",
                      },
                    ],
                  },
                  {
                    label: "Other ethnic group",
                    options: [
                      {
                        label: "Arab",
                        value: "Arab",
                      },
                      {
                        label: "Any other ethnic group",
                        value: "Any other ethnic group",
                      },
                    ],
                  },
                  {
                    label: "Prefer not to say",
                    value: "Prefer not to say",
                  },
                ]}
                colorTheme="healthcare"
              />
              <h3>Disability</h3>
              <p>
                The Equality Act (2010) protects disabled people. The Equality
                Act defines a person as disabled if they have a physical or
                mental impairment that has a ‘substantial’ and ‘long term’
                negative effect on a person’s ability to carry out normal
                day-to-day activities.
              </p>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="disability"
                label="Do you consider yourself to have a disability?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  {
                    name: "No",
                    label: "No",
                  },
                ]}
                colorTheme="healthcare"
              />
              {values.disability === "Yes" ? (
                <>
                  <FormikText
                    name="disability_info"
                    label="If answered yes, Please provide information on your disability"
                  />

                  <FormikText
                    name="disability_requirement"
                    label="Are there reasonable adjustments required in the workplace that the Company need to be aware of? If so, please provide details"
                  />
                </>
              ) : (
                ""
              )}
              <h2>OCCUPATIONAL HEALTH</h2>
              <p>
                We need to assess what needs you may have and ask you a number
                of mandatory questions about your health. This is part of our
                commitment to you, in supporting you in the position for which
                you have applied.
              </p>
              <p>
                This forms part of your employment checks therefore it is
                imperative that you complete.
              </p>
              <p>Please complete the following questions.</p>
              <p>
                <strong>
                  Please read the following statements and confirm if the five
                  statements apply to you.
                </strong>
              </p>
              <ul>
                <li>
                  Do you need any special aids/adaptations to assist you at
                  work, whether or not you have a disability?
                </li>
                <li>
                  Do you have a medical condition or disability which may affect
                  your ability to carry out your proposed work?
                </li>
                <li>
                  Are you having, or waiting for, treatment or investigation of
                  any kind at present?
                </li>
                <li>
                  Have you ever left a previous employment through ill-health or
                  a work-related injury or condition?
                </li>
                <li>
                  Do you have any back, neck or joint problems causing
                  difficulty with standing, walking, bending, lifting or stair
                  climbing?
                </li>
              </ul>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="confirm_health"
                label="Do any of the above statements apply to you?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  {
                    name: "No",
                    label: "No",
                  },
                ]}
                colorTheme="healthcare"
              />
              <h3>Medical History</h3>
              <p>
                <strong>
                  Have you had/suffered from/come into contact with any of the
                  following?
                </strong>
              </p>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="mrsa"
                  label="Methicillin Resistant Staphylococcus Aureus (MRSA)"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.mrsa === "Yes" ? (
                  <FormikDatepicker
                    name="mrsa_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="cdiff"
                  label="Clostridium Difficile (C-Diff)"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.cdiff === "Yes" ? (
                  <FormikDatepicker
                    name="cdiff_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              {values.cdiff === "Yes" || values.mrsa === "Yes" ? (
                <>
                  <p>
                    If you have indicated YES to any of the above questions you
                    must provide further details in the Additional Information
                    section below. Failure to do so will result in the form
                    being returned/rejected.
                  </p>
                  <FormikText
                    name="confirm_health_info"
                    label="Additional Information"
                  />
                </>
              ) : (
                ""
              )}
              <p>
                <strong>
                  Have you had/suffered from/come into contact with any of the
                  following?
                </strong>
              </p>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="pox_shingles"
                  label="Chicken Pox or Shingles"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.pox_shingles === "Yes" ? (
                  <FormikDatepicker
                    name="pox_shingles_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="bbv"
                  label="Blood Borne Virus (BBV) (including needle stick injuries)"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.bbv === "Yes" ? (
                  <FormikDatepicker
                    name="bbv_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <h3>Tuberculosis</h3>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="tuberculosis"
                label="Have you lived continuously in the UK for the last year (Include holidays/vacations)"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.tuberculosis === "No" ? (
                <FormikText
                  name="tuberculosis_info"
                  label="If you answer No, please list all of the countries you have lived in/visited over the last year. Please include the dates and
                duration of stay."
                />
              ) : (
                ""
              )}
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="tuberculosis_bcg"
                  label="Have you had a BCG Vaccination in relation to Tuberculosis?"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.tuberculosis_bcg === "Yes" ? (
                  <FormikDatepicker
                    name="tuberculosis_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <hr />
              <p>
                <strong>Do you have any of the following?</strong>
              </p>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="tuberculosis_cough"
                label="A cough which has lasted for more than 3 weeks?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              <FormikTabs
                setFieldValue={setFieldValue}
                name="tuberculosis_weight"
                label="Unexplained weight loss?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              <FormikTabs
                setFieldValue={setFieldValue}
                name="tuberculosis_fever"
                label="Unexplained fever?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              <FormikTabs
                setFieldValue={setFieldValue}
                name="tuberculosis_contact"
                label="Have you had Tuberculosis (TB) or been in recent contact with TB?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              <h3>Ebola Virus Disease</h3>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="ebola_countries"
                label="Have you travelled to any countries affected by Ebola? (Sierra Leone, Guinea or Liberia)"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.ebola_countries === "Yes" ? (
                <FormikText
                  name="ebola_info"
                  label="If you answer Yes, please list all of the countries you have lived in/visited over the last year. Please include the dates and
                duration of stay."
                />
              ) : (
                ""
              )}
              <h3>Exposure Prone Procedures</h3>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="epp"
                label="Will your role involve Exposure Prone Procedures?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              <h3>Immunisations</h3>
              <p>
                <strong>
                  Have you had any of the following immunisations?
                </strong>
              </p>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="triple_vaccination"
                  label="Triple vaccination as a child (Diptheria/Tetanus/Whooping Cough)"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.triple_vaccination === "Yes" ? (
                  <FormikDatepicker
                    name="triple_vaccination_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="polio"
                  label="Polio"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.polio === "Yes" ? (
                  <FormikDatepicker
                    name="polio_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="tetanus"
                  label="Tetanus"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.tetanus === "Yes" ? (
                  <FormikDatepicker
                    name="tetanus_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="varicella"
                  label="Varicella"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.varicella === "Yes" ? (
                  <FormikDatepicker
                    name="varicella_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <Col2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="mmr"
                  label="Measles, Mumps &amp; Rubella (MMR)"
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                  colorTheme="healthcare"
                />
                {values.mmr === "Yes" ? (
                  <FormikDatepicker
                    name="mmr_date"
                    placeholderText="Select a date"
                    label="If yes, please enter a date"
                  />
                ) : (
                  ""
                )}
              </Col2>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="hepatitis"
                label="Hepatitis B"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.hepatitis === "Yes" ? (
                <>
                  <p>If yes, please enter dates below</p>
                  <Col2>
                    <FormikInput
                      name="hepatitis_course_1"
                      type="text"
                      label="Course 1"
                    />
                    <FormikInput
                      name="hepatitis_boost_1"
                      type="text"
                      label="Boosters 1"
                    />
                    <FormikInput
                      name="hepatitis_course_2"
                      type="text"
                      label="Course 2"
                    />
                    <FormikInput
                      name="hepatitis_boost_2"
                      type="text"
                      label="Boosters 2"
                    />
                    <FormikInput
                      name="hepatitis_course_3"
                      type="text"
                      label="Course 3"
                    />
                    <FormikInput
                      name="hepatitis_boost_3"
                      type="text"
                      label="Boosters 3"
                    />
                  </Col2>
                </>
              ) : (
                ""
              )}
              <h3>Covid-19</h3>
              <FormikTabs
                setFieldValue={setFieldValue}
                name="covid_first_dose"
                label="Have you had your first covid vaccination?"
                tabs={[
                  { name: "Yes", label: "Yes" },
                  { name: "No", label: "No" },
                ]}
                colorTheme="healthcare"
              />
              {values.covid_first_dose === "Yes" ?
                <>
                  <Col2>
                    <FormikSelect
                      label="Name of vaccine"
                      name="covid_first_dose_name"
                      options={[
                        { value: "AstraZeneca/Oxford", label: "AstraZeneca/Oxford" },
                        { value: "Pfizer", label: "Pfizer" },
                        {
                          value: "Moderna",
                          label: "Moderna",
                        },
                      ]}
                      colorTheme="healthcare"
                    />
                    <FormikInput name="covid_first_dose_batch_number" type="text" label="Batch number" />
                    <FormikDatepicker
                      name="covid_first_dose_date"
                      placeholderText="Select a date"
                      label="When did you receive your first vaccine?"
                    />
                  </Col2>
                  <CovidUpload colorTheme="healthcare" name="proof_of_covid_dose" label="First vaccine card" />
                  <FormikTabs
                    setFieldValue={setFieldValue}
                    name="covid_second_dose"
                    label="Have you had your second covid vaccination?"
                    tabs={[
                      { name: "Yes", label: "Yes" },
                      { name: "No", label: "No" },
                    ]}
                    colorTheme="healthcare"
                  />
                  {values.covid_second_dose === "Yes" ?
                    <>
                      <Col2>
                        <FormikSelect
                          label="Name of vaccine"
                          name="covid_second_dose_name"
                          options={[
                            { value: "AstraZeneca/Oxford", label: "AstraZeneca/Oxford" },
                            { value: "Pfizer", label: "Pfizer" },
                            {
                              value: "Moderna",
                              label: "Moderna",
                            },
                          ]}
                          colorTheme="healthcare"
                        />
                        <FormikInput name="covid_second_dose_batch_number" type="text" label="Batch number" />
                        <FormikDatepicker
                          name="covid_second_dose_date"
                          placeholderText="Select a date"
                          label="When did you receive your first vaccine?"
                        />
                      </Col2>
                      <CovidUpload colorTheme="healthcare" name="proof_of_covid_dose_2" label="Second vaccine card" />
                    </>
                    : null}
                </>
                : null}
              <h2>JOB DESCRIPTION</h2>
              <p>
                <strong>JOB TITLE:</strong> Healthcare Assistant and/or Support
                Worker (Agency)
              </p>
              <p>
                <strong>RESPONSIBLE TO:</strong> Recruitment Consultant
              </p>
              <p>
                <strong>ACCOUNTABLE TO:</strong> Branch Manager
              </p>
              <h3>Main Purpose of the Job</h3>
              <p>
                To provide a range of services to the service user who requires
                support and assistance with their care needs. This usually
                involves providing a supportive and reassuring manner and
                varying levels of personal care. Healthcare Workers will also
                provide clinical observations within acute and community based
                settings, whereas Support Workers will provide community
                presence and participation within a community based setting.
              </p>
              <h3>Main Duties</h3>
              <ul>
                <li>
                  Dressing, undressing/preparing the service user for their day.
                </li>
                <li>
                  Washing/bathing/showering/shaving/grooming/cleaning teeth,
                  Hair/Nail care (washing/brushing)
                </li>
                <li>Continence care and all aspects of personal hygiene</li>
                <li>
                  Care of pressure sores (under appropriate nursing supervision
                  and training)
                </li>
                <li>
                  Use of hoist and moving equipment as appropriate to the
                  service user (in and out of bed/wheelchair) • Assist in the
                  care of the dying and bereaved, ensuring privacy and dignity
                  are maintained at all times. • Report on wellbeing of the
                  patient to the nurse in charge
                </li>
                <li>
                  Update and communicate with the nurse in charge/other
                  professionals of duties completed
                </li>
                <li>Assisting with feeding as required</li>
                <li>Promote privacy, dignity and respect</li>
                <li>Maintain a professional manner</li>
              </ul>
              <h3>Other Duties</h3>
              <ul>
                <li>
                  Complete baseline observations, Blood Pressure, Body
                  temperature, Pulse, Oxygen saturation level, Blood monitoring
                  each with appropriate training. (Healthcare Assistant only)
                </li>
                <li>
                  Report any faulty appliances, damaged equipment or potential
                  hazards
                </li>
                <li>Ability to maintain accurate records</li>
                <li>Other duties as may be reasonably agreed</li>
                <li>
                  Attend regular appraisal/monitoring/supervision meetings with
                  their line manager when required to do so.
                </li>
                <li>
                  Attend refresher training and occupational training sessions
                  as appropriate.
                </li>
              </ul>
              <h3>Responsibilities</h3>
              <p>
                Healthcare and/or Support Workers must at all times adhere to:
                Pinpoints Code of Conduct, Policies and procedures, Staff
                Handbook, Health and Social Care Act 2008 (Regulated Activities)
                Regulations 2014.
              </p>
              <h3>Notes:</h3>
              <p>
                Pin Point Health and Homecare reserve the right to amend this
                Job description from time to time, according to business needs.
                Any changes will be confirmed in writing.
                <br />
                Although this is a list of the key responsibilities of the post
                it is expected that the post holder and line manager will
                develop and define the detail of the work to be undertaken in
                addition to any reasonable requests for tasks made by the line
                manager also. Any changes to the key responsibilities will be
                the subject of consultation with the post holder. All employees
                must adhere to and perpetuate company policies and procedures
                relating to:
                <br />✓ Health and safety <br />✓ No smoking at work <br />✓
                Equal opportunities in employment, including responsibilities
                under the Equality Act 2010
              </p>
              <p>
                Your attention is drawn to the confidential nature of
                information collected within the company. The unauthorised use
                of disclosure of patients or other personal information is a
                dismissible offence and in the case of computerised information
                could result in prosecution or action for civil damages under
                The General Data Protection Regulation (GDPR) 2018.
              </p>
              <FormikCheckbox
                name="job_description_reviewed"
                label="I confirm that I have reviewed the job description and that by taking on this role I will be measured by its description."
                colorTheme="healthcare"
              />
              <h2>WORKING AS PART OF A CONSULTANCY</h2>
              <p>
                If you will be working for Pin Point as a sole trader or as part
                of a Limited Company, and not as PAYE, please mention this to
                your consultant at your interview, to make sure that you will
                receive the correct contract at your induction. We will also
                require the following information from you before your
                application will be complete and you will be able to start work:
              </p>
              <ul>
                <li>
                  Limited Company Certificate (Limited Company worker only)
                </li>
                <li>UTR Number</li>
                <li>Company Name</li>
                <li>Company Bank Account Details</li>
                <li>
                  Liability Insurance – Please note that RCN Membership is not
                  able to be used as Liability Insurance for your company.
                </li>
              </ul>
              <p>
                Please note that, depending on the establishment that you are
                working at, and the shift that you will be working, you will be
                paid different rates. Do not assume that the rate at one
                establishment will be the same at another, even belonging to the
                same care group.
              </p>
              <p>
                Please speak to your consultant when you book a shift to receive
                the rate that you will be required to state on your invoice for
                payment. For each shift that you complete, please provide us
                with a separate invoice and timesheet, so that if there are any
                issues with any of your rates, we will be able to process your
                other invoices.
              </p>
              <FormikCheckbox
                name="consultancy_paye"
                label="I will not be working as part of a consultancy and will be working as a PAYE worker."
                colorTheme="healthcare"
              />
              <FormikCheckbox
                name="consultancy_agree"
                label="I will be working as part of a consultancy."
                colorTheme="healthcare"
              />
              <FormikTabs
                setFieldValue={setFieldValue}
                name="consultancy_type"
                label="Type of consultancy working for:"
                tabs={[
                  { name: "Sole_trader", label: "Sole Trader" },
                  { name: "Limited_company", label: "Limited Company" },
                ]}
                colorTheme="healthcare"
              />
              <h3>Consultancy Details</h3>
              <Col2>
                <FormikInput
                  name="consultancy_name"
                  type="text"
                  label="Company Name"
                />
                <FormikInput
                  name="consultancy_company_number"
                  type="text"
                  label="Limited Company Number"
                />
                <FormikInput
                  name="consultancy_utr"
                  type="text"
                  label="UTR Number"
                />
              </Col2>
              <h3>Consultant to Complete</h3>
              <FormikCheckbox
                name="consultancy_active"
                label="Companies House – Company Status is Active"
                colorTheme="healthcare"
              />
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                  <Error style={{ marginTop: "1rem" }} key={e}>
                    {e}
                  </Error>
                ))
                : null}
              <SButton
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  isSubmitting ? console.log(errors) : null
                }}
                colorTheme="healthcare"
              >
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default IndustrialApplication
